























































import { defineComponent, reactive, watch } from '@vue/composition-api';
import RdDataTable from '@/components/base/data/RdDataTable.vue';
import { DataSource } from '@/models/base/data/DataSource';
import { Locomotive } from '@/models/Locomotive';
import { SimpleModel } from '@/models/base/SimpleModel';
import { DataSourceColumn } from '@/models/base/data/DataSourceColumn';
import { UrlConstants } from '@/helpers/UrlConstants';

class DictionaryDataSource<T> {
  public dictionary!: SimpleModel

  public dataSource!: DataSource<T>

  constructor(init?: Partial<DictionaryDataSource<T>>) {
    Object.assign(this, init);
  }
}

export default defineComponent({
  name: 'Dictionaries',
  components: { RdDataTable },
  props: {
    dictionary: {
      type: String,
      required: false,
      default: null,
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  setup: (props) => {
    const dictionaryDataSources = new Array<DictionaryDataSource<any>>({
      dictionary: {
        id: 'locomotives',
        name: 'Локомотивы',
      },
      dataSource: new DataSource<Locomotive>({
        baseUrl: `${UrlConstants.baseLocomotiveApi}/v1/locomotives/`,
        propertyResponseName: 'locomotives',
        columns: [
          new DataSourceColumn<Locomotive>({
            propertyName: 'id',
            propertyLabel: 'Айди',
            dataFilter: null,
            filterable: true,
            sortable: true,
            groupable: false,
            width: null,
            dataSorter: null,
          }),
          new DataSourceColumn<Locomotive>({
            propertyName: 'name',
            propertyLabel: 'Название',
            dataFilter: null,
            filterable: true,
            sortable: true,
            groupable: false,
            width: null,
            dataSorter: null,
          }),
          new DataSourceColumn<Locomotive>({
            propertyName: 'code',
            propertyLabel: 'Код',
            dataFilter: null,
            filterable: true,
            sortable: true,
            groupable: false,
            width: null,
            dataSorter: null,
          }),
        ],
      }),
    }, {
      dictionary: {
        id: 'workers',
        name: 'Работники',
      },
      dataSource: new DataSource<any>({
        baseUrl: `${UrlConstants.baseLocomotiveApi}/v1/workers/`,
        propertyResponseName: 'workers',
        columns: [
          new DataSourceColumn<Locomotive>({
            propertyName: 'id',
            propertyLabel: 'Айди',
            dataFilter: null,
            filterable: true,
            sortable: true,
            groupable: false,
            width: null,
            dataSorter: null,
          }),
        ],
      }),
    });

    const dataSources = reactive(dictionaryDataSources);

    const selectedDictionary = reactive({
      dictionary: null as unknown as DictionaryDataSource<any>,
    });
    const selectedDataSource = reactive({
      dataSource: null as unknown as DataSource<any> | null,
    });

    const dictionaryChanged = (index: number) => {
      selectedDictionary.dictionary = dataSources[index];
    };

    watch(() => selectedDictionary.dictionary,
      async (dataSource) => {
        // TODO разобраться почему не работает
        selectedDataSource.dataSource = null;
        setTimeout(() => {
          selectedDataSource.dataSource = dataSources
            .find((ds) => ds.dictionary.id === dataSource.dictionary.id)!.dataSource;
        }, 1);
      });

    if (props.dictionary != null) {
      selectedDictionary.dictionary = dataSources
        .find((ds) => ds.dictionary.id === props.dictionary)!;
    }

    return {
      selectedDictionary,
      selectedDataSource,
      dataSources,
      dictionaryChanged,
    };
  },
});
